<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="발란코드, 주문번호, 이름 등을 입력해주세요" v-model="form.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
        <!--<b-input-group-append>
          <b-button variant="light" v-b-toggle.collapse1>검색조건 <i class="fa fa-chevron-down"></i></b-button>
        </b-input-group-append>-->
      </b-input-group>

      <shop-preset v-if="$R('BALAANEER')" v-model="form.shop"></shop-preset>

      <b-button class="mt-2" variant="primary" @click="list" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
    </b-card>

    <b-alert show variant="primary">API 주문은 매 10분마다, 결제된지 10분이 지난 '주문접수' 상태의 주문에 대해 진행됩니다.</b-alert>

    <b-button class="m-1" variant="primary" @click="doAutoOrder"><b-spinner class="mr-1" small v-if="busy.autoOrder"></b-spinner>자동주문</b-button>

    <!--<b-button class="m-1" variant="success" @click="importGodo"><b-spinner class="mr-1" small v-if="busy.godo"></b-spinner>발란몰 아뜰리에, BRG 주문 가져오기</b-button>
    <b-button class="m-1" variant="success" @click="importSS"><b-spinner class="mr-1" small v-if="busy.SS"></b-spinner>SS 아뜰리에, BRG 주문 가져오기</b-button>-->
    <!--<b-button class="m-1" variant="success" @click="importGodoAtelier"><b-spinner class="mr-2" variant="secondary" small v-if="godoAtelierBusy"></b-spinner>발란몰 아뜰리에 주문 가져오기</b-button>
    <b-button class="m-1" variant="success" @click="importGodoBrg"><b-spinner class="mr-2" variant="secondary" small v-if="godoBrgBusy"></b-spinner>발란몰 BRG 주문 가져오기</b-button>-->
    <!--<b-button class="m-1" variant="info" @click="sendOrder">선택주문 발주하기</b-button>-->

    <c-table :table-data="items.list" :fields="fields" :perPage.sync="perPage" :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" :caption="items.list.length + ' 개'" @btn-clicked="btnAction" @get-more="getMore"></c-table>
  </div>
</template>

<script>
import cTable from '@/views/TableBase.vue'
import shopPreset from '@/views/ShopPreset.vue'
import {getFeed, getJson, postJson} from '@/shared/api'
import * as utils from '@/shared/utils'
import {MALL} from 'balaan_constants'
import store from '@/store';
const {state:S, getters:{R}} = store;
import {getT} from '@/shared/i18n';
const T = getT('en-US', 'ApiOrder');

export default {
  name: 'ApiOrder',
  title: 'API 주문',
  components: {cTable, shopPreset},
  data() {
    return {
      T,
      form: {
        search: '',
        shop: [],
      },
      lastBody: {list:{}},
      items: {list:[]},
      item: {detail: {}},
      busy: {list: false, listmore: false, autoOrder: false},
      hasMore: {list:false},
      ac: {list: null}, // abortController

      lastForm: {},
      fields: [
        {key:'selected'},
        {key:'html1', label:'<span class="badge badge-primary">쇼핑몰</span><br/>주문코드<br/>일련번호', sortable:true},
        {key:'_img60', label:'이미지'},
        {key:'lambda1', label:'주문일시<br/><span class="badge badge-light">주문상태</span><br/>상태일시', class:'text-center', style:{minWidth:'125px'}, sortable:true},
        {key:'html3', label:'주문자<br/>수령자', style:{minWidth:'50px'}, sortable:true},
        {key:'html6', label:'연락처<br/>휴대폰', style:{width:'100px'}, sortable:true},
        {key:'html2', label:'상품정보'},
        {key:'html7', label:'자동주문', style:{maxWidth:'150px'}, class:'text-center'},
        {key:'html4', label:R('GOODS_R') ? '판매금액<br/>(정산금액)<br/>원가' : '판매금액', style:{width:'70px',textAlign:'right'}, sortable:true},
        {key:'_actions', label:'Actions', style:{width:'230px', textAlign:'center'}, buttons: [
            {label:'원가테이블', event:'price_table', variant:'secondary'},
            {label:'StockPick', event:'stock_pick', variant:'success'},
            {label:'발주', event:'send_order', variant:'info'},
            {label:'발주-가격지정', event:'send_order_stock_pick', variant:'info'},
            {label:'자동주문결과삭제', event:'remove_auto_order_result', variant:'danger', if:e=>e.auto_order},
            {label:'JSON', event:'json', variant:'secondary'},
          ]},
      ],
      mallMap: this.$utils.arr2map(this.$C.MALL, 'name'),
      perPage: 20,
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');

    this.list();
  },
  async beforeDestroy() {
    if (this.abortController) this.abortController.abort();
  },
  watch: {
    perPage: function() {
      this.$utils.setStatus(this.$options.name, this, 'perPage');
    }
  },
  methods: {
    async list(more) {
      let form = this.form;
      let shop = form.shop.map(e=>e.shop_id);
      let body = {...form, shop};
      let j = await this.$api.postTable(this, '/order/availApiOrders', body, {more, fnAssign:this.assignTableData});
    },
    assignTableData(e) {
      let ps_log = e.ps_log ? e.ps_log.filter(e=>!e._del_dt) : [];
      let op_log = e.op_log ? e.op_log.filter(e=>!e._del_dt) : [];
      let mng_log = e.mng_log ? e.mng_log.filter(e=>!e._del_dt) : [];
      let mallObj = this.mallMap[e.mall];
      // mall 이 롯데아이몰이고 주문일자가 20.03.12 이전이라면 20% 의 수수료로 계산한다.
      if (e.mall === '롯데아이몰' && e.order_date < '2020-03-12') mallObj.fee = 20;
      let settle_price = e.sales_price * (100 - (mallObj.fee || 0)) / 100;
      let target_price = settle_price / (mallObj.feeIncludesPg === 'y' ? 0.967 : 1);
      if (e.price_table) {
        e.margin = settle_price - (e.price_table.supply.finalPrice - (mallObj.feeIncludesPg === 'y' ? e.price_table.supply.pgFee : 0));
        e.margin_rate = (e.margin > 0 ? e.margin / e.price_table.finalPriceRoundUp : e.margin / e.price_table.supply.finalPriceRoundUp) * 100;
      }
      Object.assign(e, {
        mall_fee: mallObj.fee,
        settle_price,
        marginRule: e.price_table && e.price_table.marginRule ? JSON.stringify(e.price_table.marginRule) : '',
        img: e.img_urls ? e.img_urls[0] : '',
        html1: `<a href="/#/deliveryBoard/${e.orderno}/${e.order_detailno}" target="_blank"><span class="badge badge-primary">${e.mall}</span><br/>`
          + `${e.orderno}<br/>${e.order_detailno}</a>`,
        html2: `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.boutique}</a> ` + (e.brand ? `<span class="badge badge-warning">${e.brand}</span>` : '')
          // + ` <a href="${this.MALL_URL}/shop/goods/goods_view.php?goodsno=${e.goodsno}" target="_blank"><span class="badge badge-info">${e.goodsno}</span></a> <br/>`
          + ` <a href="/#/goods/${e.goodsno}" target="_blank"><span class="badge badge-primary">${e.goodsno}</span></a> <br/>`
          + T`${e.goodsnm}<br/><span class="badge badge-secondary">${e.size}</span><span class="badge badge-info">${e.qty}</span><br/>`
          + `현재 재고 : <small><b>${e.options && e.options.length ? e.options[0].optnm : ''}</b></small>`
          + `${e.options && e.options.filter(e=>!e.not_found).map(e=>
            `<span class="badge badge-light">${e.Size}</span><span class="badge badge-${e.stock>0?'success':'secondary'}" title="판매 가능수량">${e.stock}</span>`
            + `<span class="badge badge-primary" title="SHOP 의 원본 수량">${e.shop_stock}</span>`
            + (e.order_stock ? `<span class="badge badge-info" title="결제된 주문수량">${e.order_stock}</span>` : '')
            + (e.prepared_stock ? `<span class="badge badge-warning" title="결제 전 주문수량">${e.prepared_stock}</span>` : '')
          ).join(', ')}`,
        html3: `${e.orderer.masker({intent: 'middle'})}<br/>${e.receiver.masker({intent: 'middle'})}`,
        html6: `${e.orderer_phone.masker({intent: 'phone'})}<br/>${e.orderer_mobile.masker({intent: 'phone'})}`,
        html7: e.auto_order && e.auto_order.ok === 0 ? `<div class="text-danger fs-12" style="line-height: 15px">${JSON.stringify(e.auto_order.msg || '')}</div>` : '',
        lambda2: e=>`<small class="text-truncate d-inline-block w-100" title="${e.ps_memo}">${e.ps_memo || ''}</small><br/>`
          + `${ps_log.length ? `<span class="badge badge-warning" title="PS Log\n${ps_log.map(e=>`${e._name||'?'}: ${e.content}`).join('\n')}">${ps_log.length}</span>`: ''}`
          + `${op_log.length ? `&nbsp;<span class="badge badge-info" title="운영 Log\n${op_log.map(e=>`${e._name||'?'}: ${e.content}`).join('\n')}">${op_log.length}</span>`: ''}`
          + `${mng_log.length ? `&nbsp;<span class="badge badge-success" title="주문관리 Log\n${mng_log.map(e=>`${e._name||'?'}: ${e.content}`).join('\n')}">${mng_log.length}</span>`: ''}`,
        html4: `${utils.comma(e.sales_price)}<br/>`
          + (mallObj.fee ? `(${utils.rnc(settle_price)})<br/>` : '')
          + (e.price_table ? `<span style="${e.price_table.supply.finalPriceRoundUp > target_price ? 'color:red' : 'color:grey'}">` + utils.comma(e.price_table.supply.finalPriceRoundUp) + '</span>' : '-'),
        // lambda1: e=>`${e.order_date}<br/><span class="badge badge-light">${e.order_status}</span><br/>${e[e.order_status_code+'_date']}`,
        lambda1: e=>`${e.order_dt || e.order_date}<br/><span class="badge badge-light">${e.order_status}</span><br/>${e[e.order_status_code+'_dt'] || e[e.order_status_code+'_date']}`,
      });
    },
    async doAutoOrder() {
      // if (!confirm(`발란몰에서 주문을 가져온 뒤 발주를 시작합니다. 진행하시겠습니까?`)) return;
      if (!confirm(`10분마다의 스케쥴링과 별도로 바로 진행하시겠습니까?`)) return;
      this.busy.autoOrder = true;
      let j = await getJson('/order/apiOrderAll');
      this.busy.autoOrder = false;
      if (!j) return;
      alert(`${j.cnt} 건의 주문을 진행합니다.`);
    },
    async importGodo() {
      if (!confirm(`발란몰에서 주문을 가져오시겠습니까? 발란몰에서는 배송준비 상태로 변경됩니다`)) return;
      this.busy.godo = true;
      let j = await getJson('/order/importGodoOrder?shop_id=5,55,64,71,74,75,76,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117');
      this.busy.godo = false;
      if (!j) return;
      await this.list();
      alert(`${j.cnt} 건의 주문을 가져왔습니다.`);
    },
    async importSS() {
      if (!confirm(`SS에서 주문을 가져오시겠습니까? SS에서는 배송준비 상태로 변경됩니다`)) return;
      this.busy.SS = true;
      let j = await getJson('/order/importSSOrder?shop_id=5,55,64,71,74,75,76,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117');
      this.busy.SS = false;
      if (!j) return;
      await this.list();
      alert(`${j.cnt} 건의 주문을 가져왔습니다.`);
    },
    async importGodoAtelier() {
      if (!confirm(`발란몰에서 아뜰리에 주문을 가져오시겠습니까? 발란몰에서는 배송준비 상태로 변경됩니다`)) return;
      this.godoAtelierBusy = true;
      let j = await getJson('/order/importGodoOrder?shop_id=101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117');
      this.godoAtelierBusy = false;
      if (!j) return;
      await this.list();
      alert(`${j.cnt} 건의 주문을 가져왔습니다.`);
    },
    async importGodoBrg() {
      if (!confirm(`발란몰에서 BRG 주문을 가져오시겠습니까? 발란몰에서는 배송준비 상태로 변경됩니다`)) return;
      this.godoBrgBusy = true;
      let j = await getJson('/order/importGodoOrder?shop_id=64,71,74,75');
      this.godoBrgBusy = false;
      if (!j) return;
      await this.list();
      alert(`${j.cnt} 건의 주문을 가져왔습니다.`);
    },
    sendOrder() {

    },
    showError() {
      console.log(1);
    },
    async btnAction(row, event) {
      let obj = row.item, j;
      if (event === 'price_table') {
        this.$modal.show({title:'원가정보 확인', type:'json', item:obj.price_table});
      } else if (event === 'stock_pick') {
        j = await getFeed('/fetch/stockPick?goods_no=' + obj.goodsno);
        if (j) {
          this.$modal.show({title:'StockPick', type: 'json', item: j});
        }
      } else if (event === 'send_order') {
        if (!confirm(`가격과 사이즈를 먼저 확인하시기 바랍니다. 정말로 [${obj.goodsnm}] 상품의 발주를 진행하시겠습니까?`)) return;
        j = await postJson('/order/apiOrder', {orderno:obj.orderno, order_detailno:obj.order_detailno});
        if (j) { // 성공했다면 목록에서 제거
          this.items.list.splice(this.items.list.indexOf(obj), 1);
          alert('발주에 성공했습니다');
        }
      } else if (event === 'send_order_stock_pick') {
        j = await getFeed('/fetch/stockPick?goods_no=' + obj.goodsno);
        if (!j) return;
        let price = prompt('가격을 입력해주세요', j.price);
        if (!price) return;
        if (!confirm(`가격과 사이즈를 먼저 확인하시기 바랍니다. 정말로 [${obj.goodsnm}] 상품의 발주를 ${price} 가격에 진행하시겠습니까?`)) return;
        j = await postJson('/order/apiOrder', {orderno:obj.orderno, order_detailno:obj.order_detailno, price});
        if (j) { // 성공했다면 목록에서 제거
          this.items.list.splice(this.items.list.indexOf(obj), 1);
          alert('발주에 성공했습니다');
        }
      } else if (event === 'remove_auto_order_result') {
        console.log(obj._id);
        if (!confirm(`자동주문 결과를 삭제하시겠습니까? 삭제시 다시 자동주문 대상에 들어갑니다.`)) return;
        let j = await postJson('/order/removeAutoOrderResult', {_id:obj._id});
        if (!j) return;
        await this.list();
        alert(`삭제되었습니다`);
      } else if (event === 'json') {
        this.$modal.show({type: 'json', item: obj});
      }
    },
    getMore() {
      this.list(true);
    },
  }
}
</script>
